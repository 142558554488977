import Head from 'next/head';
import Image from 'next/image'
import { useRouter } from 'next/router';
import React from 'react'

export default function Error() {
    const router = useRouter();
    return (
        <div style={{ padding: "15px", display: "flex", flexDirection: "column", width: "100vw", height: "100vh", alignItems: "center" }}>
            <Head>
                <title>Error</title>
            </Head>
            <Image
                src={"/illustrations/400.png"}
                alt="Error 400 Illustrations"
                width={300}
                height={300}
            />
            <h1 style={{ marginBottom: "15px" }}>An error occured</h1>
            <span style={{ textAlign: "center" }}>Please check the logs for more details or contact your system admin</span>

        </div>
    )
}
